@import url(../scss/Dashboard.scss);
@import url(../scss/Mixin.scss);
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Raleway", sans-serif;
}

html,
body,
#root {
  height: 100%;
}
.cursor{
  cursor: pointer;
}
img {
  max-width: 100%;
}
.notification-list-box::-webkit-scrollbar-track,
.userlist::-webkit-scrollbar-track{

  border-radius: 10px;
  background-color: transparent;
}
.notification-list-box::-webkit-scrollbar,
.userlist::-webkit-scrollbar {
  width: 4px;
  background-color: transparent;
}
.notification-list-box::-webkit-scrollbar-thumb,
.userlist::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #145340;
}
input,
select,
.btn,
button,textarea {
  &:focus {
    box-shadow: none !important;
    outline: none !important;
  }
}
textarea::placeholder,
input::placeholder {
 font-size: 14px;
}
ul {
  margin: 0px;
  padding: 0px;

  li {
    list-style: none;
  }
}

.multiselect {
  .optionListContainer {
    ul {
      li {
        &.highlightOption {
          background: #1c5141 !important;
        }

        &:hover {
          background: #1c5141 !important;
        }
      }
    }
  }
}

.btn-link{
  background-color: transparent;
  color: #343434;
  border: none;
  text-decoration: none;
  font-size: 15px;
  &:hover{
    color: #145340;
  }
}

.chip {
  background: #1c5141 !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background: #1c5141 !important;
  color: #fff !important;
}

.form-wrapper input::placeholder {
  opacity: 0.4;
}

.upcoming-user {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .user-coming {
    background-color: #efefef;
    max-width: 600px;
    height: 230px;
    margin: auto;
    border-radius: 20px;
    font-size: 36px;
    font-weight: bold;
    color: red;
    width: 100%;
    text-align: center;
    padding: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 60px;
      color: #404040;
      margin-right: 10px;
    }
  }
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show{
  background: #1c5141!important;
  border: 1px solid #1c5141!important;
}
.viewshow-icon{
  svg{
    font-size: 23px!important;
  }
}
.react-datepicker-popper{
  z-index: 99!important;
}
.fc-scrollgrid-sync-inner{
  text-transform: capitalize;
}
.h-40{
  height: 40px!important;
}
.services-dropdown{
  margin-right: 16px;
  .dropdown{
    .btn{
      border-radius:10px;
      background-color: #eff3f3!important;
      border: 2px solid #94c8c3!important;
      padding: 9px 20px;
      font-size: 16px;
    line-height: 26px;
    color: #4d4d4d;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    min-width: 180px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:focus,&:active,&:hover{
      color: #4d4d4d!important;
    }
    &::after{
      content: initial;
    }
    &[aria-expanded="false"]{

    }
    &[aria-expanded="true"]{
      .downicon{
        svg{
          transform: rotate(-180deg);
        }
      }
      
    }
    }
    .dropdown-menu{
      background: #fbfbfb;
      min-width: 180px;
      .userlist{
        padding: 0px;
        max-height: 166px;
        overflow-y: auto;
        ul{
          margin: 0px;
          padding: 0px;
          li{
            margin-bottom:6px;
            border-bottom: 1px solid #e7e1e1;
            padding-bottom: 6px;
            padding-left: 10px;
            padding-right: 10px;
            &:last-child{
              margin-bottom: 0px;
              padding-bottom: 0px;
              border-bottom: none;
            }
            input{
              padding: 0;
              height: initial;
              width: initial;
              margin-bottom: 0;
              display: none;
              cursor: pointer;
            }
            label{
              position: relative;
              cursor: pointer;
              display: flex;
              align-items: center;
              &::before{
                content:'';
                -webkit-appearance: none;
                background-color: transparent;
                border: 2px solid #69beac;
                 padding: 5px;
                display: inline-block;
                position: relative;
                vertical-align: middle;
                cursor: pointer;
                margin-right: 8px;
              }
              .name{
                text-transform: capitalize;
              }
            }
            input:checked + label:after {
              content: '';
              display: block;
              position: absolute;
              top: 1px;
              left: 8px;
              width: 6px;
              height: 14px;
              border: solid #69beac;
              border-width: 0 2px 2px 0;
              transform: rotate(45deg);
            }
          }
        }
      }
    }
  }
}
input:disabled{
  background-color: #edebeb!important;
  cursor: default;
}
.button-tooltip{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .toltip-icon{
    svg{
      font-size: 22px;
    }
  }
  .type-custom {
    left: 140px !important;
    position: absolute !important;
    top: -35px !important;
    max-width: 384px;

}
}
.verifydesc{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  flex-wrap: wrap;
  a{
    color: red;
    text-transform: capitalize;
  }
  .logout-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    a{
      background: #1c5141;
    border: 1px solid #1c5141;
    font-size: 14px;
    padding: 5px 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    color: #fff;
    width: 110px;
    height: 45px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    }
  }
}
.react-datepicker-popper {
  z-index: 9999 !important;
}
.filelink{
  font-size: 12px!important;
}
.imgpathurl{
  &.filelinkpath{
    margin-top: 5px;
  }
  .closefilelink{
    width: 18px;
    height: 18px;
    svg{

    }
  }
}
.link-field{
  a{
    text-decoration: none;
  }
}