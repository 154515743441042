@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  font-family: "Nunito", sans-serif;
}

.customer-booking-wrapper {
  .cb-wrap {
    display: flex;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }

    .col-6 {
      @media (max-width: 450px) {
        width: 100%;
      }
    }

    .cb-box {
      width: 50%;
      background-color: #f8faf9;
      padding: 50px;

      @media (max-width: 1023px) {
        padding: 25px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      @media (max-width: 450px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .cb-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        

        .cb-heading {
          .logo-circle {
            width: 100px;
            height: 100px;
            border: 1px solid #808080a6;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 20px;
            margin: auto;
            img {
              width: 100px;
              height: 100px;
              border-radius: 50%;
          }
        }
          h2 {
            font-size: 40px;
            line-height: 44px;
            text-transform: uppercase;
            color: #000000;
            font-weight: 800;
            text-align: center;
            margin-bottom: 20px;
            font-family: "Nunito", sans-serif;

            @media (max-width: 1100px) {
              font-size: 35px;
            }

            @media (max-width: 1023px) {
              font-size: 30px;
              line-height: 34px;
              margin-bottom: 15px;
            }

            @media (max-width: 450px) {
              font-size: 25px;
            }
          }

          p {
            font-size: 17px;
            line-height: 30px;
            color: #555555;
            font-weight: 300;
            text-align: center;
            font-family: "Rubik", sans-serif;

            @media (max-width: 1023px) {
              font-size: 18px;
              line-height: 24px;
            }
          }
        }

        .cb-img {
          margin-top: 60px;

          img {
            max-width: 600px;
            min-width: 600px;
            height: auto;
          }
        }
      }
    }

    .addbooking-form {
      width: 50%;
      // background-color: #134f3d;
      padding: 50px;

      @media (max-width: 1023px) {
        padding: 25px;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      @media (max-width: 450px) {
        padding-left: 15px;
        padding-right: 15px;
      }

      .revenue-field-wrapper {
        h2 {
          font-size: 32px;
          line-height: 30px;
          color: #ffffff;
          font-weight: 700;
          margin-bottom: 30px;

          @media (max-width: 1023px) {
            font-size: 26px;

            margin-bottom: 17px;
          }
        }

        .form-wrapper {
          .searchWrapper {
            height: auto;
            border-radius: 6px;
            background-color: white;
            padding-top: 4px;
            padding-bottom: 4px;
            border: 1px solid #ced4da;

            input {
              width: 100px;
              border: none;
              background-color: transparent;
              border-radius: 0px;
              height: auto;
              margin-top: 0px;
            }
          }

          label {
            font-size: 17px;
            line-height: 30px;
            color: #ffffff;
            font-weight: 300;
            font-family: "Rubik", sans-serif;
          }
          .select__input{
            height: 34px!important;
          }
          .selectinput-field{
            input{
              padding-left: 10px;
              border-left: 1px solid #ced4da;
            }
          }
          input,
          select {
            height: 48px;
            border-radius: 4px;
            background-color: #f8faf9;
            border: none;
            box-shadow: none;
            outline: none;
            width: 100%;
            font-size: 14px;
            padding: 0.375rem 10px 0.375rem 10px;
            // background-image: none;

            @media (max-width: 1023px) {
              height: 40px;
            }
          }

          .selectdate-field {
            .react-datepicker {
              width: 100%;
              border-radius: 4px;
              background-color: #ffffff;
              padding: 20px;
              position: relative;

              @media (max-width: 450px) {
                padding-left: 10px;
                padding-right: 10px;
              }

              .react-datepicker__navigation {
                position: absolute;
                top: 22px;

                span {
                  &::before {
                    border-width: 2px 2px 0 0;
                    border-color: #000000;
                  }
                }

                &.react-datepicker__navigation--previous {
                  left: 10px;
                }

                &.react-datepicker__navigation--next {
                  right: 10px;
                }
              }

              .react-datepicker__time-container {
                width: 15%;
                margin-top: 35px;

                .react-datepicker__header {
                  .react-datepicker-time__header {
                    text-transform: capitalize;
                    font-size: 16px;
                  }
                }

                .react-datepicker__time {
                  .react-datepicker__time-box {
                    width: 100%;

                    .react-datepicker__time-list {
                      .react-datepicker__time-list-item {
                        font-size: 18px;
                        line-height: 25px;
                        color: #000000;
                        font-weight: 500;
                        margin: 2px 0px;

                        &:hover {
                          background-color: #134e3d;
                          color: #ffffff;
                        }

                        &--selected {
                          background-color: #134e3d;
                          color: #ffffff;
                        }

                        &--disabled {
                          background: #eeebeb;
                          cursor: not-allowed;
                          opacity: 0.7;
                        }
                      }
                    }
                  }
                }
              }

              .react-datepicker__month-container {
                width: 85%;

                .react-datepicker__header {
                  background-color: transparent;
                  border: none;
                  padding-top: 0px;

                  .react-datepicker__current-month {
                    text-align: center;
                    margin-bottom: 10px;
                    padding-left: 0px;
                    font-size: 25px;
                    font-family: "Nunito", sans-serif;
                    line-height: 25px;
                    color: #000000;
                    font-weight: 600;
                  }

                  .react-datepicker__day-names {
                    border: 1px solid #e1e1e1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    div {
                      font-size: 18px;
                      line-height: 25px;
                      color: #000000;
                      font-weight: 700;
                      width: 14.5%;
                      padding: 11px 4px;
                      border-left: 1px solid #e1e1e1;
                      margin: 0px;
                      text-transform: uppercase;
                      font-family: "Nunito", sans-serif;

                      @media (max-width: 450px) {
                        font-size: 14px;
                      }

                      &:first-child {
                        border: none;
                      }
                    }
                  }
                }

                .react-datepicker__month {
                  margin: 0px;

                  .react-datepicker__week {
                    margin: 0px;
                    border-bottom: 1px solid #e1e1e1;
                    border-left: 1px solid #e1e1e1;
                    border-right: 1px solid #e1e1e1;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .react-datepicker__day {
                      font-size: 18px;
                      line-height: 25px;
                      color: #000000;
                      font-weight: 500;
                      width: 14.5%;
                      margin: 0px;
                      border-left: 1px solid #e1e1e1;
                      border-radius: 0px;
                      padding: 11px 4px;
                      font-family: "Nunito", sans-serif;

                      @media (max-width: 450px) {
                        font-size: 14px;
                      }

                      &--disabled {
                        background-color: #f0f0f0;
                        cursor: not-allowed;
                      }

                      &:first-child {
                        border: none;
                      }

                      &.react-datepicker__day--selected {
                        background-color: #134e3d;
                        color: #ffffff;
                      }

                      &.react-datepicker__day--keyboard-selected {
                        background-color: #134e3d;
                        color: #ffffff;
                      }

                      &:hover {
                        background-color: #134e3d;
                        color: #ffffff;
                      }
                    }
                  }
                }
              }
            }
          }

          .select-time {
            position: relative;

            .react-datepicker-popper {
              width: 100%;

              .react-datepicker.react-datepicker--time-only {
                width: 100%;

                .react-datepicker__time-container {
                  width: 100%;

                  .react-datepicker__time-box {
                    width: 100%;
                    text-align: left;
                  }
                }
              }
            }
          }

          .submitbtn {
            margin-top: 50px;

            .btn {
              background-color: #f8faf9;
              border: none;
              border-radius: 5px;
              box-shadow: none;
              color: #134f3d;
              font-size: 20px;
              font-weight: 700;
              height: 58px;
              line-height: 30px;
              margin: auto;
              outline: none;
              text-transform: uppercase;
              max-width: 140px;
              width: 100%;
              display: flex;
              align-items: center;

              @media (max-width: 1023px) {
                width: 150px;
                height: 48px;
                font-size: 17px;
              }

              svg {
                margin-left: 5px;
                min-width: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}