@import '../../assets/scss/Responsive.scss';
.product-inventory-item {
    background: #ffffff;
    padding: 10px 22px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.08);
    margin-bottom: 30px;

    .prin-item-field {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
        @include Phone{
            display: block;
        }

        .item-stored {
            p {
                margin: 0px;
                font-size: 16px;
                line-height: 26px;
                color: #333333;
                font-weight: 500;
            }

            h1 {
                margin-bottom: 0px;
                color: #40b693;
                font-size: 34px;
                font-weight: 600;
                @include Phone{
                    margin-bottom: 10px;
                }
            }
        }

        .prin-btn {
            .btn {
                color: #ffffff;
                font-size: 15px;
                border: none;
                padding: 8px 18px;
                font-weight: 400;
                border-radius:50px;
                // background-color: #134e3d;
                font-family: "Nunito", sans-serif;
                background-image: linear-gradient(148deg, rgba(45, 139, 109, 0.996) 0%, #0c4331 100%);
            }
        }
    }
}

.Product-img img {
    width: 80px;
    height: auto;
}

.userlist_wrapper {
    .userlist-layout {
        @include Phone{
            padding: 32px 9px 30px;
        }
        .user-table {
            .product-inventory-list {
                .inventory-remove{
                    width: 100px;
                    height: 35px;
                    border-radius: 20px;
                    background: #333333;
                    font-size: 14px;
                    line-height: 26px;
                    text-transform: uppercase;
                    color: #ffffff;
                    font-weight: 500;
                    border: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                table {
                    tr {
                        th {
                            div {
                                margin: auto;

                                div+div {
                                    width: 0px !important;
                                    margin: initial;
                                }
                            }
                        }

                        td {

                            text-align: center;

                            &:nth-child(4) {
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }
}