@import '../../assets/scss/Responsive.scss';
@import url("../scss/Mixin.scss");
@import url("../scss/global.scss");
@import url("../scss/Calander.scss");
@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("../scss/Userdetail.scss");
@import url("../scss/Notification.scss");

.toltip-icon {
  margin-left: 4px;
  cursor: pointer;
  svg {
    fill: #1c5141;
    font-size: 16px;
    
  }
}

.Login-wrapper {
  background: #f7f4f4;
  min-height: calc(100vh - 160px);
  padding-bottom: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .login-form {
    max-width: 450px;
    width: 100%;
    margin: 50px auto;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #efefef;
    padding: 20px 30px;

    h2 {
      font-size: 35px;
      text-align: left;
      margin-bottom: 30px;
      color: #2f3033;
    }

    .form-wrapper {
      .password-label {
        display: flex;
        justify-content: space-between;

        span.forgetpas {
          display: inline-block;
          width: 100%;
          margin-top: 10px;

          .forgetpassbtn {
            width: auto;
            padding: 0px;
            background: transparent;
            border: none;
            height: auto;
            text-transform: capitalize;
            font-weight: 600;
            font-size: 18px;
            color: #292929;
            margin-right: 0px;
            margin-left: auto;
            font-size: 16px;
          }
        }
      }
    }
  }

  .Signup-form {
    max-width: 900px;
    width: 100%;
    margin: 50px auto;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #efefef;
    padding: 20px 30px;

    h2 {
      font-size: 35px;
      text-align: left;
      margin-bottom: 30px;
      color: #2f3033;
    }
  }

  .newaccount {
    text-align: center;
    color: #292929;
    font-weight: 400;
    letter-spacing: 0.2px;
    font-size: 16px;
    margin-top: 25px;

    a {
      margin-left: 3px;
      font-size: 17px;
      color: #0000ff;
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
    }
  }
}

.headertop-wrapper {
  background: #ffffff;
  padding: 15px 0px;

  .headerbar {
    .head-rightbar{
      display: flex;
      align-items: center;
      .verifydesc{
        margin-top: 0px;
        margin-right: 40px;
        width: 100%;
      }
    }
    .logobar {
      img {
        max-width: 210px;
      }
    }

    .userbar {
      display: flex;
      align-items: center;
      .notification-btn {
        background-color: transparent;
        border: none;
        padding: 0px;
        position: relative;
        margin-right: 6px;
        svg {
          fill: #292929;
          font-size: 25px;
        }
        &:hover,
        &:focus,
        &:active,
        &:focus-visible {
          background-color: transparent !important;
          border: none !important;
        }
        .notification-value {
          position: absolute;
          background-color: #0c5543;
          color: #fff;
          border-radius: 50px;
          font-size: 10px;
          padding: 0;
          right: -18px;
          top: -8px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      span {
        color: #292929;
        font-size: 16px;
        margin-right: 10px;
        font-weight: 500;
      }

      img {
        width: 38px;
        height: 38px;
        border-radius: 50px;
        background-color: #ebebeb;
      }

      .dropdown {
        .btn {
          background-color: transparent;
          border: none;
          &:hover,
          &:focus,
          &:active,
          &:focus-visible {
            background-color: transparent !important;
            border: none !important;
          }
          &::after {
            content: initial !important;
          }

          .dropdown-menu {
            a {
              color: #292929;
            }
          }
        }
      }

      img {
        max-width: 40px;
      }
    }
  }
}

.dashboard-wrapper {
  min-height: calc(100% - 188px);

  .page-title {
    text-align: center;
    font-size: 35px;
    margin: 0px 0px 50px;
    font-weight: bolder;
    color: #272727;
  }

  .dashboard-content {
    display: flex;
    margin: 50px 0px;

    .ds-content {
      width: 100%;
      padding-left: 50px;

      .ds-goal-section {
        .goal-heading {
          ul {
            display: flex;
            padding: 0px;

            li {
              width: 33.3%;
              text-align: center;

              div {
                svg {
                  font-size: 35px;
                  fill: #17533f;
                }

                span {
                  display: block;
                  font-size: 30px;
                  font-weight: 600;
                  color: #2f3033;
                }
              }

              .goalbox {
                width: 140px;
                height: 140px;
                border: 1px solid #d5d5d5;
                margin: auto;
                border-radius: 50%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background: #f9f9f9;
                box-shadow: 0px 1px 31px #eeecec;
              }
            }
          }
        }

        .ds-goal-table {
          margin-top: 50px;

          .dstable {
            background: #f9f9f9;

            ul {
              margin-bottom: 0px;
              padding: 0px;
              border: 1px solid #e9e5e5;
              border-radius: 3px;

              li {
                .dslist {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  .title {
                    padding-left: 20px;
                  }

                  .dsbtn {
                    width: 200px;
                    text-align: center;
                    position: relative;
                    padding: 10px;
                    border-left: 1px solid #e9e5e5;

                    .startbtn {
                      background: #2f3033;
                      border-color: #2f3033;
                      font-size: 14px;
                      padding: 5px 15px;
                      letter-spacing: 0.5px;
                    }

                    .editbtn {
                      background: #1c5141;
                      border: 1px solid #1c5141;
                      font-size: 14px;
                      padding: 5px 15px;
                      letter-spacing: 0.5px;
                    }

                    .btn {
                      margin: 0px 5px;
                    }
                  }
                }

                &:first-child {
                  border-bottom: 1px solid #e9e5e5;
                }
              }
            }
          }

          .bgform {
            margin-bottom: 50px;

            .form-wrapper {
              margin-bottom: 10px;

              label {
                color: #292929;
                font-weight: 500;
                letter-spacing: 0.2px;
                font-size: 14px;
              }

              .form-label {
                margin-bottom: 10px;
              }

              input,
              select {
                height: 40px;
                background-color: #f9f9f9;
              }

              .formtitle {
                label {
                  display: inline-flex;
                  align-items: center;
                  font-size: 25px;
                  font-weight: 600;
                  text-transform: capitalize;
                  margin-bottom: 10px;

                  input {
                    margin-right: 10px;
                    transform: scale(1.5);
                  }
                }
              }

              .col-6 {
                display: flex;

                .mb-3 {
                  display: flex;
                  justify-content: flex-end;
                  flex-direction: column;
                  width: 100%;
                }
              }
            }

            h1 {
              font-size: 35px;
              text-align: left;
              margin-bottom: 15px;
              margin-top: 30px;
              color: #2f3033;

              .toltip-icon {
                cursor: pointer;

                svg {
                  fill: #17533f;
                  font-size: 22px;
                }
              }
            }

            .gd-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 20px 0px 40px;

              .btn {
                margin: 0px 10px;
                background-color: #17533f;
                color: #fff;
                border-color: #17533f;
                min-width: 120px;
                font-size: 17px;
              }
            }

            .personal-budget-box {
              h2 {
                font-weight: 500;
                font-size: 18px;
                text-align: center;
              }

              h1 {
                font-size: 30px;
                text-align: center;
                margin-bottom: 30px;
                color: #2f3033;
              }

              .average-list {
                margin-bottom: 30px;
                margin-top: 60px;

                ul {
                  margin: 0px;
                  padding: 0px;
                  display: flex;
                  justify-content: space-between;

                  li {
                    width: 25%;

                    span {
                      display: block;
                      font-size: 20px;
                      text-align: center;
                      font-weight: 700;
                      color: #2f3033;

                      &.cl-value {
                        margin-bottom: 15px;
                      }
                    }
                  }

                  &.avgicon {
                    text-align: center;

                    li {
                      svg {
                        font-size: 55px;
                        margin-bottom: 15px;
                      }
                    }
                  }
                }
              }

              .btn {
                background-color: #17533f;
                color: #fff;
                border-color: #17533f;
                min-width: 120px;
                font-size: 17px;
                margin: auto;
                text-align: center;
                display: block;
              }
            }
          }

          .number-tab-wrapper {
            .nav-tabs {
              border: none;

              li {
                width: 31.3%;
                margin: 0px 1%;

                button {
                  display: block;
                  padding: 15px 6px;
                  text-align: center;
                  line-height: 23px;
                  color: #262626;
                  font-size: 16px;
                  font-weight: 500;
                  text-transform: uppercase;
                  text-decoration: none;
                  transition: all 0.3s ease-out 0s;
                  background: #e8e8e8;
                  border-radius: 10px;
                  width: 100%;

                  &.active {
                    background: #17533f;
                    color: #fff;
                  }
                }
              }
            }

            .number-table {
              table {
                thead {
                  tr {
                    th {
                      padding: 10px 15px;
                      background: #3c4744;
                      color: #fff;
                    }
                  }
                }

                tbody {
                  border: none;

                  tr {
                    border: 0.5px solid #f0eeee;

                    th {
                      padding: 10px 15px;
                      color: #262626;
                      font-size: 16px;
                      font-weight: 500;
                    }

                    td {
                      padding: 10px 15px;
                    }

                    &:nth-child(odd) {
                      th,
                      td {
                        --bs-table-accent-bg: #f5f5f5;
                      }
                    }

                    &:hover > * {
                      --bs-table-accent-bg: #f5f5f5;
                    }
                  }
                }
              }
            }
          }
        }

        .booking-goal-table {
          margin-top: 25px;

          table {
            thead {
              tr {
                th {
                  padding: 10px 15px;
                  background: #3c4744;
                  color: #fff;

                  &:first-child {
                    padding-left: 25px;
                  }
                }
              }
            }

            tbody {
              border: none;

              tr {
                border: 0.5px solid #f0eeee;

                td {
                  padding: 10px 15px;
                  vertical-align: middle;

                  .btn {
                    background: #1c5141;
                    border: 1px solid #1c5141;
                    font-size: 14px;
                    padding: 5px 15px;
                    letter-spacing: 0.5px;
                  }

                  &:first-child {
                    padding-left: 25px;
                  }
                }

                &:nth-child(odd) {
                  th,
                  td {
                    --bs-table-accent-bg: #f5f5f5;
                  }
                }

                &:hover > * {
                  --bs-table-accent-bg: #f5f5f5;
                }
              }
            }
          }
        }
      }

      .user-wrapper {
        h3 {
          font-size: 30px;
          text-align: left;
          margin-bottom: 0px;
          color: #2f3033;
        }

        .user-searchbar {
          display: flex;
          justify-content: space-between;
          margin: 25px 0px;

          .search-bar {
            display: flex;
            align-items: center;
            position: relative;
            width: 100%;
            max-width: 350px;
            margin-right: 20px;

            .icon {
              position: absolute;
              right: 0;
              background: #17533f;
              width: 40px;
              height: 40px;
              border-top-right-radius: 0.25rem;
              border-bottom-right-radius: 0.25rem;
              top: 0px;
              bottom: 0px;
              display: flex;
              align-items: center;
              justify-content: center;

              svg {
                fill: #ffffff;
              }
            }

            input {
              height: 40px;
              background-color: #f9f9f9;
              padding-right: 50px;
            }
          }

          .adduser {
            background-color: #17533f;
            color: #fff;
            border-color: #17533f;
            min-width: 120px;
            font-size: 17px;
          }
        }

        .user-table {
          table {
            border-top: none;

            thead {
              tr {
                th {
                  padding: 10px 15px;
                  background: #3c4744;
                  color: #fff;
                }
              }
            }

            tbody {
              border-top: none;

              tr {
                border: 0.5px solid #f0eeee;

                td {
                  padding: 10px 15px;
                }
              }
            }
          }
        }

        ul.pagination {
          justify-content: end;
          margin-top: 30px;

          .page-item.active .page-link {
            background-color: #17533f;
            border-color: #17533f;
          }
        }
      }
    }

    .ds-calendar {
      width: 100%;
      padding-left: 50px;

      .ds-cl-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
          margin-right: 20px;
          margin-bottom: 0px;
          font-size: 30px;
          font-weight: 600;
          color: #2f3033;
        }

        .bookingbtn {
          margin-left: 20px;
          background-color: #17533f;
          color: #fff;
          border-color: #17533f;
          min-width: 120px;
          font-size: 17px;
        }
      }

      .clanader-form {
        margin-top: 25px;
        background: rgba(232, 232, 232, 1);
        padding: 30px;
        border-radius: 2px;
      }
    }
  }
}

.form-wrapper {
  .pas-word {
    position: relative;

    .password-btn {
      background-color: transparent;
      padding: 0px;
      border: none;
      width: auto;
      position: absolute;
      top: 6px;
      right: 10px;
      height: auto;

      &.notshow {
        opacity: 0.7;

        &:after {
          content: "";
          border-bottom: 2px solid #1c5141;
          width: 25px;
          height: 2px;
          position: absolute;
          top: 11px;
          right: -1px;
          transform: rotate(45deg);
        }
      }

      svg {
        font-size: 22px;
        fill: #1c5141;
      }
    }
  }

  label {
    letter-spacing: 0.2px;
  }
  .business-type-feild{
    .multiSelectContainer{
      text-align: left;
      .search-wrapper{
        .chip{
          margin-top: 3px;
          margin-bottom: 3px;
        }
      }
      &.disable_ms{
        .search-wrapper{
          .chip{
            img{
              display: none;
            }
          }
        }
      }
    }
    .search-wrapper{
    
      #search_input{
        padding-left: 12px;
        &:disabled{
          background-color: transparent!important;
          display: none;
        }
      }
    }
  }
  select {
    height: 45px;
    background-color: #f9f9f9;
  }

  .btn {
    background: #1c5141;
    border: 1px solid #1c5141;
    font-size: 14px;
    padding: 5px 15px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    width: 110px;
    height: 45px;
    margin: auto;
    display: block;
  }

  .submit {
    margin-top: 20px;
  }
}

.sidebarmenu {
  .pro-sidebar {
    .pro-sidebar-inner {
      background: #e8e8e8;
      border-radius: 10px;

      .pro-sidebar-layout {
        .pro-sidebar-header {
          border: none;
        }

        .pro-menu {
          margin-top: 10px;
          margin-bottom: 10px;

          ul {
            li {
              border-bottom: 1px solid #c0bfbf;
              padding: 0px 15px;

              .pro-inner-item {
                padding: 9px 35px 9px 10px;
                border-radius: 10px;
                margin-bottom: 0px;

                .pro-icon-wrapper {
                  background: #e8e8e8;
                  border-radius: 5px;
                  margin-right: 10px;

                  svg {
                    fill: #1d5041;
                    font-size: 22px;
                  }
                }

                .pro-item-content {
                  color: #262626;
                  font-size: 16px;
                  font-weight: 500;

                  a {
                    color: #262626;
                    font-size: 16px;
                    font-weight: 500;
                  }
                }
              }

              &:hover,
              &.active {
                background: #17533f;

                .pro-item-content {
                  color: #ffffff;

                  a {
                    color: #ffffff;
                  }
                }

                .pro-icon-wrapper {
                  background: transparent;

                  svg {
                    fill: #ffffff !important;
                  }
                }
              }

              &:last-child {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }
}

.budget-form-wrapper {
  background: #f7f4f4;
  min-height: 100%;
  padding-bottom: 50px;
  display: inline-block;
  width: 100%;

  .budget-form-box {
    margin-top: 50px;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #efefef;

    .MuiPaper-root {
      background-color: transparent;

      .MuiStepConnector-root {
        // &:nth-child(2){
        //   .MuiStepConnector-line{
        //     &::after{
        //       content: '';
        //       background: #1c5141;
        //       position: absolute;
        //       height: 3px;
        //       left: -10px;
        //       right: 0px;
        //       width:140%;
        //       top: -15px;
        //     }
        //   }
        // }
        &:nth-child(2) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -10px;
              right: 0px;
              width: 215%;
              top: -15px;
            }
          }
        }

        &:nth-child(4) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -32px;
              right: 0px;
              width: 240%;
              top: -15px;
            }
          }
        }

        &:nth-child(6) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -19px;
              right: 0px;
              width: 250%;
              top: -15px;
            }
          }
        }

        &:nth-child(8) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -35px;
              right: 0px;
              width: 323%;
              top: -15px;
            }
          }
        }

        &:nth-child(10) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -48px;
              right: 0px;
              width: 305%;
              top: -15px;
            }
          }
        }

        &:nth-child(12) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -27px;
              right: 0px;
              width: 305%;
              top: -15px;
            }
          }
        }

        &:nth-child(14) {
          .MuiStepConnector-line {
            &::after {
              content: "";
              background: #1c5141;
              position: absolute;
              height: 3px;
              left: -49px;
              right: 0px;
              width: 275%;
              top: -15px;
            }
          }
        }
      }
    }

    .MuiCollapse-root {
      transition: initial !important;
    }

    .steplabel {
      flex-direction: column;

      .MuiStepLabel-labelContainer {
        margin-top: 7px;

        .MuiStepLabel-label {
          font-size: 15px;
        }
      }

      .MuiStepIcon-root {
        width: 30px;
        height: 30px;

        &.MuiStepIcon-completed {
          color: #1c5141;
        }
      }
    }

    .MuiStepConnector-root {
      position: relative;

      .MuiStepConnector-line {
        position: absolute;
        border: none;
        width: 38px;

        @media (max-width: 1500px) {
          width: 38px;
        }

        @media (max-width: 1400px) {
          width: 30px;
        }

        // &::after{
        //   content: '';
        //   background: #1c5141;
        //   position: absolute;
        //   height: 4px;
        //   left: -52px;
        //   right: 0px;
        //   width: 100px;
        //   top: -16px;
        // }
      }

      &:nth-child(2) {
        .MuiStepConnector-line {
          @media (max-width: 1400px) {
            width: 24px;
          }
        }
      }

      &:nth-child(4) {
        .MuiStepConnector-line {
          @media (max-width: 1400px) {
            width: 28px;
          }
        }
      }

      &:nth-child(6) {
        .MuiStepConnector-line {
          @media (max-width: 1400px) {
            width: 28px;
          }
        }
      }

      &:nth-child(8) {
        .MuiStepConnector-line {
          @media (max-width: 1400px) {
            width: 28px;
          }
        }
      }
    }

    .steper-form {
      display: block;

      .MuiStepContent-root {
        border: none;
        margin: 0px;
        padding: 0px;

        .MuiStepLabel-label {
          font-weight: 500;
        }
      }
    }

    .stepbtn {
      display: flex;
      justify-content: space-between;
      padding: 0px 35px 20px;

      .nextbtn {
        background-color: #17533f;
        color: #fff;
        padding-left: 15px;
        padding-right: 15px;
      }

      .bkbtn,
      .resetbtn {
        background-color: #2f3033;
        color: #ffffff;

        &.Mui-disabled {
          background-color: #cecece;
        }
      }

      .submitbtn {
        a {
          margin-left: 10px;
        }
      }
    }

    .bugdet-form {
      .title {
        font-size: 30px;
        text-align: left;
        margin-bottom: 30px;
        color: #2f3033;

        &.sub {
          margin-top: 30px;
        }
      }

      .form-wrapper {
        label {
          color: #292929;
          font-weight: 500;
          letter-spacing: 0.2px;
          font-size: 14px;

          .toltip-icon {
            margin-left: 0px;
          }
        }

        input {
          height: 40px;
          background: #f9f9f9;
        }
      }
    }
  }
}

.bugdet-form-summary {
  .total-income-value {
    margin-top: 22px;

    h1 {
      font-size: 18px;
      line-height: 26px;
      color: #14513e;
      font-weight: 800;
      font-family: "Nunito Sans", sans-serif;
      margin-bottom: 20px;
    }

    .Total-incomebox {
      border: 1px solid #13513d;

      tr {
        background-color: #e8e8e8;
        border-bottom: 1px solid #d2d2d2;

        td {
          font-size: 14px;
          padding: 0px 25px;
          line-height: 47px;
          color: #5d5d5d;
          font-family: "Nunito Sans", sans-serif;
          font-weight: 700;
        }

        &:last-child {
          background-color: #14513e;

          td {
            color: #ffffff;
            padding-top: 4px;
            padding-bottom: 4px;
          }
        }
      }
    }
  }

  .summary-list {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  .sum-list-box {
    border-top: none;
    border: 1px solid #dcdcdc;
    border-radius: 15px;
    background-color: #f8f8f8;

    .summary-heading {
      padding: 0px 15px;
      margin: 0px;

      h2 {
        font-size: 18px;
        line-height: 26px;
        color: #5d5d5d;
        font-weight: 700;
        font-family: "Nunito Sans", sans-serif;
        margin-bottom: 0px;
      }
    }
  }

  .summary-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 20px;

    h1 {
      font-size: 30px;
      text-align: left;
      margin-bottom: 0px;
      color: #2f3033;
    }

    .btn {
      background: #1c5141;
      border: 1px solid #1c5141;
      font-size: 14px;
      padding: 5px 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    &:nth-child(3) {
      margin-top: 8px;
    }
  }

  .summary-listbar {
    table {
      margin-bottom: 0px;
      border-top: none;

      th {
        padding: 10px 15px;
        color: #fff;
      }

      tbody {
        border-top: none;
        padding-top: 5px;
        padding-bottom: 5px;

        tr {
          border: none;

          &:hover {
            --bs-table-accent-bg: #f8f8f8;
            --bs-table-hover-bg: #f8f8f8;
          }

          &:first-child {
            td {
              padding-top: 15px;
            }
          }

          &:last-child {
            td {
              padding-bottom: 15px;
            }
          }

          &:nth-of-type(odd) {
            td {
              --bs-table-accent-bg: #f8f8f8;
              --bs-table-hover-color: #f8f8f8;
            }
          }

          td {
            padding: 4px 15px;
            font-size: 14px;
            line-height: 30px;
            color: #5d5d5d;
            font-weight: 600;
            font-family: "Nunito Sans", sans-serif;
            border: none;
            background-color: #f8f8f8;

            &:first-child {
              width: 80%;
            }

            & + td {
              font-size: 14px;
              line-height: 30px;
              color: #5d5d5d;
              font-family: "Nunito Sans", sans-serif;
              font-weight: 800;
            }
          }
        }
      }

      tfoot {
        border-top: 1px solid #eeeeee;

        tr {
          border: none;

          &:first-child {
            td {
              padding-top: 10px;
            }
          }

          &:last-child {
            td {
              padding-bottom: 10px;
            }
          }

          td {
            padding: 0px 15px;
            font-size: 14px;
            line-height: 30px;
            color: #5d5d5d;
            font-weight: 800;
            font-family: "Nunito Sans", sans-serif;
            border: none;
          }
        }
      }
    }

    .Total-incomebox {
      margin-top: 30px;
      background: #f9f9f9;

      tr {
        td {
          padding: 10px 15px;

          &:first-child {
            width: 80%;
          }

          &:last-child {
            font-weight: 700;
            border-left: 0.5px solid #f0eeee;
          }
        }
      }
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 9999;
    background-color: rgba(200, 198, 198, 0.75) !important;

    .video-popup {
      max-width: 760px;
      width: 100%;
      margin: auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0px;
      right: 0px;
      background: #ffff;
      border-radius: 5px;
      box-shadow: 0px 1px 5px #efefef;
      padding: 20px 30px;
      z-index: 99;

      .popup-box {
        h2 {
          font-size: 25px;
          text-align: left;
          margin-bottom: 20px;
          color: #2f3033;
        }

        .closebtn {
          position: absolute;
          top: -10px;
          right: -10px;
          background: #1c5141;
          border: none;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px;

          &:hover {
            background-color: #292929;
          }
        }
      }

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.footer-wrapper {
  background-color: #4d4d4d;
  padding: 25px 0px;
  text-align: center;

  .copyright {
    font-size: 17px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Rubik", sans-serif;
  }
}

.error {
  color: red;
  font-size: 14px;
  display: block;
  margin-right: 10px;
}
.error-field {
  display: flex;
  align-items: center;
  .error {
    &:first-child {
      width: 240px;
    }
  }
}
.forgetpopup,
.user-modal,
.service-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        .modal-title {
          font-size: 23px;
          text-align: left;
          margin-bottom: 0px;
          color: #2f3033;
        }
      }

      .modal-body {
        input,
        select {
          height: 40px;
          background: #f9f9f9;
          width: 100%;
          border: 1px solid #ced4da;
          padding: 0px 10px;
          font-size: 1rem;
          font-weight: 400;
          border-radius: 0.25rem;
        }

        .time-duration {
          display: flex;
          flex-wrap: nowrap;

          span {
            font-size: 17px;
            width: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
          }
        }
      }

      .modal-footer {
        .btn-primary {
          background-color: #17533f;
          color: #fff;
          border-color: #17533f;
        }
      }
    }
  }
}

.forgetpopup {
  .form-group {
    label {
      margin-bottom: 10px;
      line-height: 19px;
      font-size: 16px;
      font-weight: 500;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.forget-password-wrap {
  background: #f7f4f4;
  min-height: calc(100% - 139px);
  padding-bottom: 50px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  .forget-pasbox {
    max-width: 450px;
    width: 100%;
    margin: 50px auto;
    background: #ffff;
    border-radius: 5px;
    box-shadow: 0px 1px 5px #efefef;
    padding: 20px 30px;

    .heading {
      font-size: 30px;
      text-align: left;
      margin-bottom: 20px;
      color: #2f3033;
    }

    label {
      color: #292929;
      font-weight: 500;
      letter-spacing: 0.2px;
      font-size: 16px;
    }

    input {
      height: 45px;
      background-color: #f9f9f9;
    }

    .btn {
      background: #1c5141;
      border: 1px solid #1c5141;
      font-size: 14px;
      padding: 5px 15px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      width: 165px;
      height: 45px;
      margin: 25px auto auto auto;
      display: block;
    }

    .signin {
      a {
        font-size: 17px;
        color: #0000ff !important;
        font-weight: 500;
        cursor: pointer;
        text-decoration: none;
        display: block;
        text-align: center;
        margin-top: 15px;
      }
    }
  }
}

.service-provided {
  margin-top: 30px;

  h2.title {
    text-align: center;
    font-size: 25px;
  }

  .service-list {
    margin: 30px 0px;
    padding: 0px;
    display: flex;
    flex-wrap: wrap;

    .service-goal {
      width: auto;
      margin-bottom: 20px;

      label {
        position: relative;
        cursor: pointer;

        input {
          padding: 0;
          height: initial;
          width: initial;
          margin-bottom: 0;
          cursor: pointer;
          opacity: 0;
        }

        .name {
          color: #292929;
          font-weight: 500;
          letter-spacing: 0.5px;
          font-size: 16px;

          &::before {
            content: "";
            -webkit-appearance: none;
            background-color: transparent;
            border: 2px solid #17533f;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
              inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
          }
        }

        input:checked + span:after {
          content: "";
          display: block;
          position: absolute;
          top: 5px;
          left: 11px;
          width: 6px;
          height: 14px;
          border: solid #17533f;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }

      .goalcheckbox {
        width: 100%;
        min-width: 240px;

        .name {
          margin-left: 10px;
          text-transform: capitalize;
        }

        input[type="checkbox"] {
          transform: scale(1.2);
        }
      }
    }

    .service-list-detail {
      width: 100%;
      margin-top: 15px;
      display: inline-flex;
      flex-wrap: wrap;
      margin-left: -1%;
      margin-right: -1%;

      .mb-3 {
        width: 48%;
        margin: 0px 1%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        label {
          color: #292929;
          font-weight: 500;
          letter-spacing: 0.2px;
          font-size: 14px;
        }

        input,
        select {
          height: 40px;
          background-color: #f9f9f9;
          opacity: 1;
        }
      }
    }

    .showdetail {
      display: block;
      width: 100%;

      label {
        width: 100%;
      }
    }

    .hiddendetail {
      display: none;
    }
  }
}

.form-error {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.service-time {
  display: flex;
  justify-content: space-between;

  div {
    width: 48%;

    &:first-child {
      margin-right: 1%;
    }

    &:last-child {
      margin-left: 1%;
    }
  }
}

.Toastify__toast-body {
  div {
    justify-content: start;
    text-align: left;
  }
}

.replay-btn {
  background: #2f3033;
  border-color: #2f3033;
  font-size: 14px;
  padding: 5px 15px;
  letter-spacing: 0.5px;
  margin-right: 20px;

  &:hover,
  &:active,
  &:focus-visible {
    background: #2f3033;
    border-color: #2f3033;
  }
}

.plan-wrapper {
  margin: 50px 0px;
  font-family: "Nunito Sans", sans-serif;

  .pricing-wrapper {
    .pricing-detail-wrap {
      .plan-heading {
        text-align: center;
        padding: 40px 15px;

        h1 {
          font-size: 34px;
          font-weight: 700;
          color: #404040;
          margin-bottom: 20px;
        }

        P {
          color: #404040;
          font-size: 16px;
          font-weight: 500;
          max-width: 650px;
          margin: auto;
        }
      }

      .pricing-detail {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 50px;
        flex-wrap: wrap;
        .pricing-list {
          width: 260px;
          filter: drop-shadow(0 0 41.5px rgba(201, 201, 201, 0.49));
          background-color: #ffffff;
          padding: 30px 15px;
          margin: 6px;
          border-radius: 20px;
          
          .pr-heading {
            margin-bottom: 20px;

            h2 {
              font-size: 18px;
              line-height: 22px;
              color: #414040;
              font-weight: 700;
              text-align: center;
            }
          }

          .pricing-box {
            width: 157px;
            height: 157px;
            border-radius: 78px;
            background-color: #71ab7c;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;

            .pricing {
              width: 142px;
              height: 142px;
              border-radius: 71px;
              background-color: #ffffff;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;

              h2 {
                font-size: 26px;
                line-height: 26px;
                color: #14513f;
                font-weight: 800;
                text-align: center;
                margin-bottom: 0px;

                sup {
                  font-size: 15px;
                  line-height: 22px;
                  color: #145340;
                  font-weight: 800;
                }
              }

              p {
                margin-bottom: 0px;

                b {
                  font-size: 14px;
                  line-height: 15px;
                  color: #282828;
                  font-weight: 800;
                  text-align: center;
                }

                font-size: 14px;
                line-height: 15px;
                color: #282828;
                padding: 0px 15px;
                text-align: center;
              }
            }
          }

          .pricing-option-list {
            margin: 20px 0px;

            ul {
              margin: 0px;
              padding: 0px;

              li {
                font-size: 14px;
                line-height: 20px;
                color: #404040;
                font-weight: 700;
                text-align: center;
                padding: 12px 0px;
                position: relative;

                &::after {
                  content: "";
                  width: 186px;
                  height: 1px;
                  background-color: #f9f9f9;
                  position: absolute;
                  bottom: 0px;
                  left: 0px;
                  right: 0px;
                  margin: auto;
                }
              }
            }
          }

          .btn {
            width: 190px;
            height: 45px;
            border-radius: 8px;
            background-color: #29674d;
            margin: auto;
            display: block;
            border: none;
            font-size: 16px;
            line-height: 30px;
            color: #ffffff;
            font-weight: 700;
          }

          &.annual-pricing-list {
            position: relative;
            z-index: 99;
            width: 260px;
            border-radius: 25px;
            padding: 40px 15px;
            background-image: linear-gradient(
              118deg,
              #97cd93 0%,
              #13533f 99%,
              #13533f 100%
            );
            border: 2px solid #29674d;
            box-shadow: none;
            filter: none;

            .recm-title {
              width: 177px;
              height: 30px;
              background-color: #fec05b;
              position: absolute;
              left: 0px;
              right: 0px;
              top: -15px;
              margin: auto;
              display: flex;
              align-items: center;
              justify-content: center;

              &::before {
                content: "";
                position: absolute;
                bottom: -21px;
                left: -10px;
                border-top: 16px solid #c58d31;
                border-left: 10px solid transparent;
                border-bottom: 20px solid transparent;
              }

              &::after {
                content: "";
                position: absolute;
                bottom: -21px;
                right: -10px;
                border-top: 16px solid #c58d31;
                border-right: 10px solid transparent;
                border-bottom: 20px solid transparent;
              }

              h1 {
                font-size: 14px;
                line-height: 22px;
                color: #3c3325;
                font-weight: 700;
                margin-bottom: 0px;
                text-align: center;
              }
            }

            .pr-heading {
              padding: 0px 20px;
              margin-bottom: 30px;

              h2 {
                font-size: 20px;
                line-height: 24px;
                color: #ffffff;
                font-weight: 800;
                margin-bottom: 15px;
              }

              p {
                font-size: 16px;
                line-height: 20px;
                color: #ffffff;
                font-weight: 600;
                text-align: center;
              }
            }

            .pricing-box {
              .pricing {
                h2 {
                  sup {
                  }
                }

                p {
                  b {
                  }
                }
              }
            }

            .pricing-option-list {
              ul {
                li {
                  color: #ffffff;
                }
              }
            }

            .btn {
              background-color: transparent;
              border: 1px solid #ffffff;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
}

.layout-wrapper {
  filter: drop-shadow(0 0 41.5px rgba(201, 201, 201, 0.49));
  background-color: #ffffff;
  border-radius: 20px;
  padding-bottom: 50px;

  .breadcurm-bar {
    background-color: #efefef;
    padding: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

    h2 {
      font-size: 18px;
      line-height: 34px;
      color: #404040;
      font-weight: 600;
      margin-bottom: 0px;
      font-family: "Nunito Sans", sans-serif;
    }

    nav {
      margin-bottom: 0px;

      ol {
        margin-bottom: 0px;
        display: flex;
        align-items: center;

        li {
          &.breadcrumb-item {
            svg {
              fill: #145340;
              font-size: 25px;
            }

            font-size: 14px;
            line-height: 26px;
            color: #3f3f3f;
            font-weight: 600;
            font-family: "Nunito Sans", sans-serif;

            &.active {
            }
          }
        }
      }
    }
  }
}

.myaccount-wrapper {
  .myaccount-layout {
    margin: 50px 0px;
    padding-bottom: 20px;

    .account-detail {
      padding: 25px;

      .account-subheading {
        h2 {
          font-size: 27px;
          font-weight: 600;
          color: #404040;
          margin-bottom: 2px;
        }

        p {
          color: #404040;
          font-size: 16px;
          font-weight: 500;
        }
      }

      .form-wrapper {
        .btn {
          margin-top: 30px;
        }

        .currentplan-field {
          display: flex;
          align-items: center;

          input {
            width: calc(100% - 155px);
          }

          .planbtn {
            background: transparent;
            border: 1px solid #1c5141;
            border-radius: 5px;
            cursor: pointer;
            margin-left: 15px;
            margin-top: 0px;
            padding: 2px 20px;
            height: 45px;
            color: #1c5141;
            font-size: 15px;
            font-weight: 500;

            a {
              color: #1c5141;
              font-size: 14px;
              line-height: 26px;
              text-decoration: none;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}

.signupinfo-form {
  background: #f7f4f4;
  display: inline-block;
  width: 100%;
  height: calc(100vh - 63px);

  @media (max-width: 1400px) {
    height: auto;
  }

  &.plan-payment-info {
    .heading {
      background: #e8e8e8;
      padding: 15px 0px;
      box-shadow: 0px 1px 5px #cecece;
      text-align: center;
      font-size: 30px;
    }

    .payment-info {
      display: flex;
      align-items: center;
      height: calc(100% - 63px);

      @media (max-width: 1400px) {
        height: auto;
      }

      .card-payment-box {
        max-width: 600px;
        width: 100%;
        margin: 50px auto;
        background: #ffff;
        border-radius: 5px;
        box-shadow: 0px 1px 15px #cecece;
        padding: 25px 30px;
        position: relative;
        .cross-btn {
            background-color: #000;
            border: 1px solid #000;
            opacity: 1;
            text-transform: capitalize;
            font-size: 14px;
            letter-spacing: 0.5px;
            font-weight: 500;
            padding: 4px 11px;
            cursor: pointer;
            position: absolute;
        }

        h2 {
          font-size: 28px;
          text-align: left;
          margin-bottom: 8px;
          color: #2f3033;
        }

        h4 {
          color: #145340;
          text-align: center;
          font-weight: 700;
          font-size: 31px;
          letter-spacing: 0px;
          margin-bottom: 13px;
        }

        p {
          font-size: 14px;
          color: #404040;
        }

        .card-payment {
          margin-bottom: 15px;

          .StripeElement {
            background-color: #212529;
            padding: 10px;
          }
        }

        .payment-information {
          margin-bottom: 30px;

          a {
            font-size: 15px;
            color: #0000ff;
            font-weight: 400;
            cursor: pointer;
            text-decoration: none;
          }

          span {
            color: #404040;
            font-weight: 400;
            letter-spacing: 0.2px;
            font-size: 15px;
            margin-left: 10px;
            position: relative;

            &::before {
              content: "";
              -webkit-appearance: none;
              background-color: transparent;
              border: 2px solid #1c5141;
              box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
                inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
              padding: 7px;
              display: inline-block;
              position: relative;
              vertical-align: middle;
              cursor: pointer;
              margin-right: 5px;
            }
          }

          input {
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }

          input:checked + span:after {
            content: "";
            display: block;
            position: absolute;
            top: 6px;
            left: 7px;
            width: 5px;
            height: 10px;
            border: solid #1c5141;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }

        .bsbtn {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;

          .btn {
            background: #1c5141;
            border: 1px solid #1c5141;
            font-size: 16px;
            padding: 5px 15px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}

.Coupon-option {
  margin-bottom: 20px;
  text-align: center;

  h5 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 9px;
  }

  input {
    height: 40px;
    border: 1px solid #939393;
    border-radius: 8px;
    padding: 10px;
  }
}

.layout-loaderbar {
  position: fixed;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background: rgba(232, 232, 232, 0.7);
  z-index: 9;

  .loaderbar {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;

    div {
      justify-content: center;

      svg {
        fill: #145340;
      }
    }
  }
}

.signupinfo-form {
  &.plan-payment-info {
    @media (max-width: 1400px) {
      height: auto;
    }
  }
}

.download-csvbtn {
  position: absolute;
  margin-top: 24px;
  display: block;
  margin-left: 105px;

  .csvButton {
    height: 40px;
    border-radius: 8px;
    background-color: #14513e;
    font-size: 14px;
    border: none;
    line-height: 26px;
    padding-left: 15px;
    padding-right: 15px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Nunito Sans", sans-serif;
    margin-left: 12px;
  }
}

.login-page-wrapper {
  background-image: linear-gradient(135deg, #97dda4 0%, #0c5543 100%);
  min-height: 100%;

  .footer-wrapper,
  .Login-wrapper {
    background: transparent;
  }
}
.expire-container {
  background-color: #f5f5f5;
  background-image: unset;
  .login-form {
    max-width: 100%;
    text-align: center;
    background-color: transparent;
    box-shadow: none;
    svg {
    width: 90px;
    height: 90px;
    text-align: center;
    fill: #00b289;
    margin-bottom: 40px;
    }
    .title {
      margin-bottom: 0;
      text-align: center;
      font-size: 28px;
      font-weight: 700;
    }
  }
  .copyright {
    color: #000;
  }
}
.account-detail {
  .form-wrapper {
    position: relative;
    min-height: 315px;

    .loader {
      .loading {
        top: 50%;
        transform: translateY(-50%);
        left: 0px;
        right: 0px;
      }
    }
  }
}

.user-inovice-table {
  margin-top: 10px;

  .submitbtn {
    display: flex;
    justify-content: flex-end;
  }

  .table {
    tr {
      &:nth-child(even) {
        background-color: #f2f2f2;
      }
    }

    thead {
      border: 1px solid #14513e;
      border-bottom: none;

      th {
        background-color: #14513e;
        font-size: 16px;
        line-height: 26px;
        text-transform: uppercase;
        color: #ffffff;
        font-weight: 600;
        font-family: "Nunito Sans", sans-serif;
        padding: 10px;
      }
    }

    tbody {
      border: 1px solid #ced4da;
      border-top: none;

      tr {
        background-color: #ffffff;

        td {
          font-size: 16px;
          line-height: 20px;
          color: #333333;
          font-weight: 400;
          border: none;
          background: none;
          font-family: "Nunito Sans", sans-serif;
          text-transform: capitalize;
          padding: 15px 10px;

          .pending {
            color: #ea4c89;
            font-weight: 500;
          }

          .approved {
            color: #14513e;
            font-weight: 500;
          }
        }
      }
    }
  }
}

.service-form {
  padding: 20px;
  width: 100%;
  min-height: 600px;
  h1 {
    font-size: 23px;
    color: #404040;
    font-weight: 500;
  }
  .bkservice {
    max-width: 600px;
    width: 100%;
    margin-top: 30px;
    .select__clear-indicator,
    .select__indicator-separator {
      display: none !important;
    }
    .select__control {
      position: relative;
      &::after {
        content: "Select...";
        color: #808080;
        position: absolute;
        left: 10px;
        background-color: #ffffff;
      }
      .select__value-container {
        .select__multi-value {
          display: none;
        }
      }
    }
    .select-servicelist {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      span {
        display: flex;
        min-width: 0;
        align-items: center;
        margin: 2px;
        box-sizing: border-box;
        border-radius: 24px;
        background-color: #eff3f3;
        border: 2px solid #94c8c3;
        padding: 6px 10px 7px 15px;
        font-size: 14px;
        line-height: 16px;
        text-transform: capitalize;
        .btn {
          -webkit-align-items: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          display: -webkit-box;
          display: -webkit-flex;
          display: -ms-flexbox;
          display: flex;
          border-radius: 2px;
          padding-left: 4px;
          padding-right: 4px;
          box-sizing: border-box;
          background: transparent;
          border: none;
          margin-left: 4px;
          &:focus,
          &:focus-visible,
          &:active {
            background-color: transparent !important;
            border: none !important;
          }
          svg {
            fill: #14513e;
            color: #14513e;
          }
        }
      }
      p {
        margin-bottom: 0px;
        margin-right: 16px;
      }
    }
  }
  .basic-multi-select {
    .select__control {
      border-radius: 6px;
      background-color: #ffffff;
      border: 1px solid #e7e7e7;
      font-size: 16px;
      line-height: 26px;
      color: #999999;
      font-weight: 600;
      font-family: "Nunito", sans-serif;
    }

    .select__menu {
      div {
        font-size: 16px;
        line-height: 26px;
        color: #222222;
        font-weight: 600;
        font-family: "Nunito", sans-serif;
      }
    }
  }
  label {
    font-size: 16px;
    line-height: 21px;
    color: #4d4d4d;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
  }

  input {
    border-radius: 6px;
    // background-color: #f9f9f9;
    // border: 1px solid #e7e7e7;
    font-size: 16px;
    line-height: 26px;
    color: #999999;
    font-weight: 600;
    font-family: "Nunito", sans-serif;
  }
  .submitbtn {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    .btn {
      border-radius: 8px;
      background-color: #14513e;
      border: 1px solid #14513e;
      width: 100%;
      max-width: 115px;
      padding: 12px 0px 8px;
      font-size: 14px;
      line-height: 16px;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 700;
      font-family: "Nunito", sans-serif;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;

      svg {
        margin-left: 5px;
        position: relative;
        top: -2px;
      }
    }
  }
}

.boooking-remove {
  color: #ffffff;
  font-size: 16px;
  border: none;
  background-image: linear-gradient(
    148deg,
    rgba(45, 139, 109, 0.996) 0%,
    #0c4331 100%
  );
  padding: 12px 24px;
  font-weight: 400;
  border-radius: 50px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-family: "Nunito", sans-serif;
}
.notification-wrapper {
  position: absolute;
  top: 55px;
  right: 0;
  width: 400px;
  z-index: 999;
  background-color: #f8f8f8;
  transform: translateY(-120%);
  transition: transform 0.5s ease-in-out;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  &-enter {
    transform: translateY(0%);
    &-done {
      transform: translateY(0%);
    }
  }
  &-exit {
    transform: translateY(100%);
    &-active {
      transform: translateY(-120%);
    }
  }
}

.open-notification {
  overflow-x: hidden;
}
.notification-wrapper {
  .notification-box {
    .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #dddddd;
      .btn {
        margin-right: 16px;
        svg {
          fill: #333333;
          font-size: 20px;
        }
        &:hover,
        &:focus,
        &:active {
          background: transparent !important;
          border: none !important;
        }
      }
    }
    h2 {
      font-family: "Nunito Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      color: #404040;
      border-bottom: 1px solid #d9d9d9;
      padding: 15px 20px 15px 25px;
      margin-bottom: 0px;
    }
    .notification-list-box {
      max-height: 300px;
      overflow-y: auto;
      padding-right: 4px;
      width: calc(100% - 3px);
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .notification-lists {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        padding: 10px 20px 10px 20px;
        background: #fbfbfb;
        position: relative;
        &:hover {
          background: #f9f9f9 !important;
        }
        &:last-child {
          border-bottom: none;
        }
        &.nonreadble {
          background-color: #efefef;
        }

        .notification-detail {
          padding-right: 10px;
          display: flex;
          align-items: baseline;
          .nth-profileicon {
            margin-right: 10px;
            img {
              border-radius: 50px;
              width: 30px;
              height: 30px;
              min-width: 30px;
              object-fit: cover;
            }
          }
          h3 {
            font-style: normal;
            font-family: "Nunito", sans-serif;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
            color: #333333;
            margin-bottom: 4px;
          }
          p {
            font-style: normal;
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.1px;
            line-height: 16px;
            color: #333333;
            margin-bottom: 0px;
          }
          ul {
            margin: 0px;
            padding: 0px;
            display: flex;
            align-items: center;
            li {
              margin-right: 34px;
              margin-left: 0px;
              position: relative;
              &::after {
                content: "";
                background-color: #333333;
                width: 6px;
                height: 6px;
                position: absolute;
                top: 11px;
                right: -20px;
                margin: auto;
                border-radius: 50px;
              }
              &:last-child {
                &::after {
                  content: initial;
                }
              }
              span {
                font-family: "Nunito", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 18px;
                color: #145340;
              }
            }
          }
        }
        .btn {
          svg {
            font-size: 18px;
          }
        }
      }
    }
    .notfiction-btn {
      padding: 10px 20px;
      text-align: center;
      border-top: 1px solid #d9d9d9;
      background-color: #14513e;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      .btn {
        color: #f5f5f5;
        text-transform: capitalize;
        font-size: 16px;
        font-weight: 500;
        outline: none;
      }
    }
    .btn {
      background-color: transparent;
      outline: none;
      box-shadow: none;
      padding: 0px;
      border: none;
    }
  }
}
.event-list-opt {
  position: relative;
  .event-option-list {
    position: relative;
    background: transparent;
    width: 100%;
    filter: none;
    &.sub-option-list {
      position: absolute;
      left: 240px;
      top: 75px;
      filter: none;
      padding: 0px;
      background: transparent;
      .color-setting-list {
        padding-top: 0px;
        .csl-list {
          padding-top: 0px;
        }
      }
    }
  }
}
.eventlist-modal {
  .modal-dialog {
    max-width: 380px;
    .modal-content {
      .modal-header {
        border: none;
        padding-bottom: 0px;
        .event-heading {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          width: 100%;
          h1 {
            font-size: 24px;
            color: #333333;
          }
          .clsbtn {
            background: transparent;
            padding: 0px;
            border: none;
            &:focus,
            &:active {
              background: transparent !important;
              border: none !important;
            }
            svg {
              fill: #333333;
              font-size: 22px;
            }
          }
        }
      }
      .modal-body {
        padding-top: 0px;
      }
    }
  }
}
.fc-list-table {
  tbody {
    tr {
      &.fc-list-event {
        cursor: pointer;
        .fc-list-event-title {
          a {
            font-size: 15px;
          }
        }
      }
    }
  }
}
.user-information-des {
  text-align: left;
  margin-bottom: 25px;
  p {
    margin-bottom: 4px;
    font-size: 14px;
    b {
      color: #14513e;
    }
  }
}
.user-information-desc {
  margin-bottom: 20px;
  text-align: left;
  p {
    b {
      color: #14513e;
    }
  }
}

.billing_wrapper {
  padding: 25px;
  width: calc(100% - 270px);
  @include Phone{
    overflow-x: scroll;
    width: 100%;
  }
  @include TabletLargesize{
    width: 100%;
  }
  .billing_heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -5px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -6px;
    padding: 20px;
    h4 {
      font-size: 18px;
      margin-bottom: 0;
      font-weight: 600;
      font-family: "Nunito", sans-serif !important;
    }
    .invoice_btn {
      background-color: #14513e;
      border-color: #14513e;
      font-family: "Nunito", sans-serif !important;
      border-radius: 50px;
      padding: 8px 20px;
      font-size: 16px;
    }
  }
  .billing_table_heading {
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    position: relative;
    z-index: 999;
    .option_box {
      align-items: center;
      gap: 5px;
      span {
        font-weight: 500;
        font-size: 15px;
        font-family: "Nunito", sans-serif !important;
      }
      .form-select {
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 0px;
      }
    }
    .table_search {
      form {
        align-items: center;

        label {
          font-size: 16px;
          line-height: 26px;
          color: #333333;
          font-weight: 500;
          margin-right: 15px;
        }

        input {
          width: 200px;
          height: 42px;
          background-color: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 0px;
        }
      }
    }
    &.staff-list-wrap {
      margin-top: 30px;
    }
  }
  .billing_table {
    width: 100%;
    .staflist-table{
      overflow-y: auto;
      .MuiTable-root{
        padding: 0px;
        border-spacing: 0px;
      }
    }
    @include TabletLargesize{
      overflow-x: scroll;
      
    }
    table {
      border-collapse: separate;
      border-spacing: 0 15px;
      background: #f6f6f6;
      border: none;
      box-shadow: none !important;
      padding: 20px;
      overflow: scroll;
      thead {
        tr {
          th {
            background-color: #14513e;
            font-size: 15px;
            line-height: 26px;
            text-transform: uppercase;
            color: #ffffff;
            font-weight: 600;
            font-family: "Nunito Sans", sans-serif;
            white-space: nowrap;
          }
        }
      }

      tbody {
        tr {
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
          td {
            .action-btn {
              .btn {
                border: none;
                background-color: transparent;
                outline: none;
                box-shadow: none;
                padding: 5px 8px;
                &:hover,&:focus,&:active,&:focus-visible,&:visited{
                  background: transparent!important;
                  border: none!important;
                }
                svg {
                  transform: rotate(0deg);
                  fill: #d45700;
                  font-size: 20px;
                }
              }
            }
          }

          
          td {
            font-size: 15px;
            font-weight: 600;
            font-family: "Nunito Sans", sans-serif;
            white-space: nowrap;
            .table_status {
              align-items: center;
              gap: 5px;
              .dot {
                width: 7px;
                height: 7px;
                border-radius: 50px;
              }
            }
            .table_btn {
              .download_btn {
                background-color: transparent;
                border-color: transparent;
                color: #14513e;
                align-items: center;
                font-weight: 700;
                gap: 5px;
                svg {
                  width: 17px;
                  height: 17px;
                  // transform: rotate(45deg);
                }
              }
            }
          }
        }
      }
    }
    .pagination_box {
      margin-top: 20px;
      .pagination {
        justify-content: center;
        align-items: center;
        height: 20px;
        li {
          a {
            border: 0;
            font-size: 15px;
            font-weight: 600;
            font-family: "Nunito", sans-serif;
            color: #000;
            background-color: transparent;
            &:focus{
              outline: none;
              box-shadow: none;
            }
          }
          &:first-child {
            .page-link {
              svg {
                fill: #22a785;
              }
            }
          }
          &:last-child {
            .page-link {
              svg {
                fill: #22a785;
              }
            }
          }
        }
        .active {
          .page-link {
            background-color: #22a785;
            border-color: #22a785;
            width: 29px;
            display: flex;
            border-radius: 5px;
            font-size: 12px;
            font-family: "Nunito", sans-serif;
            height: 29px;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.payment_wrapper {
  background-color: #f6f6f6;
  .payment_header {
    background-color: #fff;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    img {
      max-width: 180px;
    }
  }
  .payment_main_box {
    height: calc(100vh - 156px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .payment_content_box {
    max-width: 500px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: 1px solid #c1c1c1;
    border-radius: 8px;
    margin: 20px 0;
    min-height: 300px;

    .payment_content_inner {
      margin: 20px;
      width: 100%;
      padding: 25px;
      background-color: #f6f6f6;
      text-align: center;
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .payment_icon {
        svg {
          width: 80px;
          height: 80px;
          fill: #14513e;
        }
      }

      .payment_content {
        h6 {
          font-size: 18px;
          font-weight: 600;
          margin: 10px 0;
          font-family: "Nunito", sans-serif;
        }
        p {
          font-family: "Nunito", sans-serif;
          color: #575757;
        }
        .payment_btn {
          background-color: #0c4331;
          border-color: #0c4331;
          font-size: 15px;
          min-width: 100px;
          font-family: "Nunito", sans-serif;
          text-transform: uppercase;
        }
      }
    }
  }
}
.payment_failed_wrapper {
  .payment_main_box {
    .payment_icon {
      svg {
        fill: #ef513a !important;
      }
    }
    .payment_content {
      h6 {
        color: #ef513a;
      }
      .payment_btn {
        background-color: #ef513a !important;
        border-color: #ef513a !important;
        min-width: 130px !important;
      }
    }
  }
}

.generate_link_modal {
  .modal-header {
    padding: 0 0 16px 0;
    margin: 16px 16px 0 16px;
    justify-content: space-between;
    .generate_header {
      h3 {
        font-size: 18px;
        border-left: 3px solid #0c4331;
        color: #0c4331;
        padding-left: 10px;
        font-weight: 700;
        font-family: "Nunito", sans-serif;
      }
      p {
        font-size: 15px;
        font-family: "Nunito", sans-serif;
      }
    }
    .close_btn {
      background-color: #0c5543;
      border-color: #0c5543;
      width: 30px;
      height: 30px;
      border-radius: 50px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -10px;
      right: -10px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .generate_content_box {
    .form-error{
      width: 100%;
      display: inline-block;
    }
    .generate_logo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .generate_img {
        .imgloader{
          background: #f2f2f2;
          width: 100px;
          height: 100px;
          border-radius: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          >span{
            &:first-child{
              width: 46px!important;
              height: 54px!important;
            }
            span{
              width: 3px!important;
              height: 10px!important;
            }
          }
        }
        img {
          width: 100px;
          min-width: 100px;
          height: 100px;
          min-height: 100px;
          border-radius: 50px;
          border: 1px solid #c1c1c1;
          object-fit: cover;
        }
      }
      .generate_upload {
        position: relative;
        .input_file {
          opacity: 0;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          width: 123px;
        }
        .upload_btn {
          align-items: center;
          gap: 10px;
          .upload_img {
            background-color: #0c4331;
            border-color: #0c4331;
            display: flex;
            align-items: center;
            gap: 5px;
            text-transform: uppercase;
            font-size: 15px;
          }
        }
      }
    }
  }
  .generate_change_box {
    .generate_change_input {
      margin: 20px 0;
      .showimg{
        margin-left: 16px;
        width: 100%;
      }
      .imgloader{
        background: #f2f2f2;
        width: 100%;
        height: 100px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        >span{
          &:first-child{
            width: 46px!important;
            height: 54px!important;
          }
          span{
            width: 3px!important;
            height: 10px!important;
          }
        }
      }
      .form-label {
        font-size: 15px;
        margin-bottom: 5px;
        svg {
          color: #0c4331;
        }
      }
      .input_upload_btn {
        background: #f9f9f9;
        text-align: end;
        border: 1px solid #c1c1c1;
        padding: 5px;
        border-radius: 5px;
        position: relative;
        .upload_btn {
          min-width: 100px;
          min-height: 35px;
          background-color: #94cf94;
          border-color: #94cf94;
          font-size: 15px;
          color: #0c4331;
        }
        .input_file {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
        }
      }
      .input_colorpicker {
        min-height: 45px;
        background: #f9f9f9;
        border: 1px solid #c1c1c1;
        padding: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        input {
          width: 30px;
          height: 30px;
          border-radius: 5px;
        }
      }
    }
  }
  .generate_update_box {
    .generate_update_heading {
      h4 {
        font-size: 18px;
           font-family: "Nunito", sans-serif;
           border-bottom: 1px solid #c1c1c1;
           padding-bottom: 10px;
           margin-bottom: 20px;
           font-weight: 700;
      }
    }
    .input_field_box {
        .form-label {
        font-size: 15px;
        margin-bottom: 5px;
        svg {
          color: #0c4331;
        }
      
      }
      textarea{
        min-height: 150px;
      }
      .form-control {
          background: #f9f9f9;
        border: 1px solid #c1c1c1;
        padding: 10px 20px; 
        border-radius: 5px;
        min-width: 45px;
        font-family: "Nunito", sans-serif;
        &::placeholder {
          color: #b9b9b9;
          font-family: "Nunito", sans-serif;
        }
      }
    }
  }
  .generate_payment_method {
    .form-error{
      display: inline-block;
      width: 100%;
    }
    .payment_head {
        font-size: 15px;
        svg {
          color: #0c4331;
        }
    }
     
    .generate_payment_checkbox {
      display: flex;
      gap: 20px;
     
      .form-check {
        .form-check-input {
          &:checked {
            background-color: #0c4331;
            border-color: #0c4331;
          }
        }
        .form-check-label {
          font-size: 15px;
        }
      }
    }
  }
  .modal-footer {
    justify-content: center;
    .btn-primary {
      background-color: #0c4331;
      border-color: #0c4331;
      min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      svg {
        fill: #87a79d;
      }
    }
  }
}

.staff-modal{
  .modal-head {
    font-size: 20px;
    font-weight: 400;
    padding-left: 10px;
position: relative;
    &::before {
      position: absolute;
      content: '';
      width: 4px;
      height: 29px;
      background-color: #002b1ffa;
      left: 0px;
      top: -1px;
      border-radius: 5px;
  }
}
.para-line {
  font-size: 16px;
    font-weight: 400;
    color: #4d4d4d;
}
.btn-close{
  background-color: #002b1f;
  opacity: 5;
  border-radius: 20px;
  padding: -18px;
  position: relative;
  top: -46px;
  left: 21px;
 
}
.modal-body {
  .addstaf-togglelist{
    background-color: #ffffff;
    padding:20px 16px;
    margin-bottom: 26px;
  }
  .form-feild {
    .form-label {
      font-size: 15px;
      line-height: 21px;
      color: #4d4d4d;
      font-weight: 600;
      font-family: 'Nunito', sans-serif;
    }
    input {
      border-radius: 6px;
      background-color: #f9f9f9;
      border: 1px solid #e7e7e7;
      font-size: 16px;
      line-height: 26px;
      color: #4d4d4d;
      font-weight: 600;
      font-family: 'Nunito', sans-serif;
  }
  }
  .page-moodule{
    h6{
      font-weight: 400;
      color: #000;
      padding: 15px 0px;
      font-size: 17px;
      span{
        svg{
          fill: #002b1f;
        }
      }
    }
  }
  .switch-toggle{
    border: 1px solid #94c8c3;
    border-radius: 50px;
    margin-bottom: 14px;
    background-color: #eff3f3;
    padding: 4px;
    &.switch-on{
      background-color: #94c8c3;
    }
    .MuiFormControlLabel-root {
      display: flex;
      justify-content: space-between;
      .MuiFormControlLabel-label{
          font-size: 15px;
          line-height: 21px;
          color: #4d4d4d;
          font-weight: 600;
          font-family: 'Nunito', sans-serif;
          white-space: nowrap;
      }
      span.MuiSwitch-root{
        &.MuiSwitch-sizeMedium{
          margin-right: 13px;
          width: 70px;
           height: 42px;
      .MuiSwitch-switchBase{
        &.Mui-checked {
          -webkit-transform: translateX(30px);
          -moz-transform: translateX(30px);
          -ms-transform: translateX(30px);
          transform: translateX(30px);
          .MuiSwitch-thumb {
            color: #ffffff;
          }
          &+.MuiSwitch-track{
            background-color: #006b62;
            opacity: 1;
          }
      }
        .MuiSwitch-thumb {
          color: #006b62;
          position: relative;
          top: 2.5px;
        }
      }
      }
      }
    
      .MuiSwitch-track{
        background-color: #fff;
        border: 1px solid #006b62;
        height: 20px;
        border-radius: 50px
      }
    }
  }
}
.add-btn{
  justify-content: center;
  display: flex;
  margin-bottom: 10px;
    .btn-primary {
      background-color: #0c4331;
      border-color: #0c4331;
      min-width: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      svg {
        fill: #c1c0c0;
      }
    }
  }
}


.selectinput-field{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  select{
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
    width: auto!important;
    border-right: 0px!important;
    background-color: #f9f9f9!important;
    padding-right: 30px!important;
    &:focus{
      border-color: #dee2e6!important;
    }
    &:disabled{
      background-color: #edebeb!important;
    }
  }
  input{
    border-top-left-radius: 0px!important;
    border-bottom-left-radius: 0px!important;
    min-width: auto;
  }
}

.tooltiplabel{
  position: relative;
  .type-custom {
    left: 140px!important;
    max-width: 384px;
    position: absolute!important;
    top: -10px!important;
  }
}

.staff-modal{
  .modal-dialog{
    max-width: 900px;
    .modal-content{
      .modal-header{
        position: relative;
        border-bottom: none;
        .modal__header{
          border-bottom: 1px solid #dee2e6;
          width: 100%;
        }
        .clsbtn{
          position: absolute;
          right: -10px;
          top: -10px;
          background-color: #14513e;
          border-color: #14513e;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          padding: 0px;
          svg{
            fill: #ffffff;
          }
        }
      }
      .modal-body{
        .add-staff-field{
          background-color: #f6f6f6;
          padding: 20px;
          .form-feild{
            input{
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }
}
.report-revenue-table{
  .MuiPaper-root{
    .MuiTableContainer-root{
      .MuiTable-root{
        .MuiTableHead-root{
          .MuiTableRow-root{
            .MuiTableCell-head{
              &:last-child{
                .Mui-TableHeadCell-Content{
                  .Mui-TableHeadCell-Content-Labels{
                    .Mui-TableHeadCell-Content-Wrapper{
                      opacity: 0;
                    }
                  }
                }
              }
            }
          }
        }
        .MuiTableBody-root{
          .MuiTableRow-root{
            .MuiTableCell-root{
              &:last-child{
                .MuiButtonBase-root{
                  svg{
                    transform: rotate(90deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.de-activate-btn{
  button{
    background: transparent;
    border: 1px solid #1c5141;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 15px;
    margin-top: 0px;
    padding: 2px 20px;
    height: 45px;
    color: #1c5141;
    font-size: 15px;
    font-weight: 500;
    margin-top: 26px;
  }
}