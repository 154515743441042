@import '../../assets/scss/Responsive.scss';
.react-calendar {
  width: 600px;
  max-width: 100%;
  background: white;
  border: 1px solid #a0a096;
  line-height: 1.125em;
  margin: auto;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer>* {
  width: 50%;
  margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 44px;
  margin-bottom: 1em;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: #17533f;
  font-size: 30px;
}

.react-calendar__navigation__label span {
  color: #17533f;
  font-size: 22px;
  font-weight: 500;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #17533f;
  color: #ffffff;
}

.react-calendar__tile--now {
  background: #ffff76;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  background: #17533f;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #17533f;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}

.full-calendar-layout {
  position: relative;
  background-color: #f6f6f6;
  padding: 20px;

  .flc-layout-wrap {
    background-color: #ffffff;
    padding-top: 15px;
    position: relative;
  }

  .fc-header-toolbar {
    height: 50px;
    padding: 5px 15px;
    background-image: linear-gradient(133deg, #0fb3a7 0%, #40b693 99%, #40b693 100%);
    margin-bottom: 82px !important;

    .fc-toolbar-chunk {
      width: 33.3%;

      &+.fc-toolbar-chunk {
        div {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &+.fc-toolbar-chunk {
          text-align: right;

          .fc-button-group {
            justify-content: flex-end;
            margin-right: 15px;

          }
        }
      }

      div {
        // display: flex;
        // align-items: center;

        .fc-toolbar-title {
          font-size: 16px;
          line-height: 26px;
          color: #ffffff;
          font-weight: 500;
          font-family: 'Nunito', sans-serif;
          text-align: center;
        }

        .fc-prev-button,
        .fc-next-button {
          background-color: transparent;
          border: none;
          outline: none;
        }
      }

      .fc-button-group {
        button {
          font-size: 16px;
          line-height: 26px;
          color: #ffffff;
          font-weight: 500;
          font-family: 'Nunito', sans-serif;
          background: transparent;
          border: none;
          border-radius: 4px;
          padding: 4px 15px;
          text-transform: capitalize;

          &.fc-button-active {
            background-color: #ffffff;
            color: #13513e;
          }
        }
      }
    }
  }

  .fc-view-harness {
    max-width: calc(100% - 20px);
    width: 100%;
    margin: auto;

    .fc-view {
      table {
        thead {
          tr {
            th {
              background-color: #13513e;
              height: 40px;
              vertical-align: middle;
              @include Phone{
                width: 100px;
              }
              a {
                font-size: 15px;
                line-height: 26px;
                color: #ffffff;
                font-weight: 600;
                font-family: 'Nunito', sans-serif;
                text-align: center;
                text-decoration: none;
              }
            }
          }
        }

        tbody {
          tr {
            &.fc-list-event {
              td {
                font-size: 14px;
                line-height: 26px;
                color: #000000;
                font-weight: 500;
                font-family: 'Nunito', sans-serif;
              }
            }

            th {
              @include Phone{
                width: 100px;
              }
              div {
                a {
                  font-size: 16px;
                  line-height: 26px;
                  color: #13513e;
                  font-weight: 700;
                  font-family: 'Nunito', sans-serif;
                  text-decoration: none;
                }
              }
            }

            td {
              .fc-daygrid-day{
                width: 100px;
              .fc-daygrid-day-frame {
                background-color: #ededed;

                .fc-daygrid-day-top {
                  justify-content: center;
                  align-items: center;

                  a {
                    font-size: 14px;
                    line-height: 26px;
                    color: #000000;
                    font-weight: 500;
                    font-family: 'Nunito', sans-serif;
                    text-decoration: none;
                  }
                }

              }
            }

              // &.fc-day-today {
              //   .fc-daygrid-day-frame {
              //     .fc-daygrid-day-events {
              //       background-color: #ffb5c1;
              //     }
              //   }
              // }

              .fc-timegrid-slot-label-frame {
                text-align: center;
                font-size: 14px;
                line-height: 26px;
                color: #000000;
                font-weight: 500;
                font-family: 'Nunito', sans-serif;
                text-decoration: none;
              }

              .fc-timegrid-axis-frame {
                span {
                  text-align: center;
                  font-size: 14px;
                  line-height: 26px;
                  color: #000000;
                  font-weight: 500;
                  font-family: 'Nunito', sans-serif;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .calendar-selectdate {
    display: block;
    width: 100%;
    text-align: right;
    max-width: 370px;
    margin: auto 0px 15px auto;
    padding-right: 15px;

    .sdate-btn {
      height: 40px;
      border-radius:50px;
      background-image: linear-gradient(148deg, rgba(45, 139, 109, 0.996) 0%, #0c4331 100%);
      font-size: 16px;
      line-height: 26px;
      text-transform: capitalize;
      color: #ffffff;
      font-weight: 500;
      border: none;
      padding: 0px 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto 0px auto auto;

    }

    .react-datepicker__tab-loop {
      position: relative;
      z-index: 99;

      .react-datepicker-popper {
        z-index: 9;
        width: 100%;

        .react-datepicker {
          width: 100%;
          border-radius: 4px;
          background-color: #ffffff;
          padding: 14px;
          position: relative;

          @media(max-width:450px) {
            padding-left: 10px;
            padding-right: 10px;
          }

          .react-datepicker__triangle {
            width: 230px;

            &::before {
              right: 0px;
              left: auto;
            }

            &::after {
              right: 0px;
              left: auto;
            }
          }

          .react-datepicker__navigation {
            position: absolute;
            top: 15px;

            span {
              &::before {
                border-width: 2px 2px 0 0;
                border-color: #000000;
              }
            }

            &.react-datepicker__navigation--previous {
              left: 2px;

            }

            &.react-datepicker__navigation--next {
              right: 2px;
            }
          }


          .react-datepicker__month-container {
            width: 100%;

            .react-datepicker__header {
              background-color: transparent;
              border: none;
              padding-top: 0px;

              .react-datepicker__current-month {
                text-align: center;
                margin-bottom: 10px;
                padding-left: 0px;
                font-size: 17px;
                font-family: 'Nunito', sans-serif;
                line-height: 25px;
                color: #000000;
                font-weight: 600;
              }

              .react-datepicker__header__dropdown {
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                div {
                  select {
                    height: 38px;
                    border-radius: 6px;
                    background-color: #ffffff;
                    font-size: 16px;
                    line-height: 20px;
                    color: #4d4d4d;
                    font-weight: 600;
                    width: 100%;
                    min-width: 150px;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    padding: 0.375rem 0.75rem;
                    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    font-family: "Nunito Sans", sans-serif;
                  }
                }
              }

              .react-datepicker__day-names {
                border: 1px solid #e1e1e1;
                display: flex;
                align-items: center;
                justify-content: space-between;

                div {
                  font-size: 14px;
                  line-height: 25px;
                  color: #000000;
                  font-weight: 700;
                  width: 14.5%;
                  padding: 4px;
                  border-left: 1px solid #e1e1e1;
                  margin: 0px;
                  text-transform: uppercase;
                  font-family: 'Nunito', sans-serif;

                  @media(max-width:450px) {
                    font-size: 14px;
                  }

                  &:first-child {
                    border: none;
                  }
                }
              }
            }

            .react-datepicker__month {
              margin: 0px;

              .react-datepicker__week {
                margin: 0px;
                border-bottom: 1px solid #e1e1e1;
                border-left: 1px solid #e1e1e1;
                border-right: 1px solid #e1e1e1;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .react-datepicker__day {
                  font-size: 14px;
                  line-height: 25px;
                  color: #000000;
                  font-weight: 500;
                  width: 14.5%;
                  margin: 0px;
                  border-left: 1px solid #e1e1e1;
                  border-radius: 0px;
                  padding: 4px;
                  font-family: 'Nunito', sans-serif;

                  @media(max-width:450px) {
                    font-size: 14px;
                  }

                  &:first-child {
                    border: none;
                  }

                  &.react-datepicker__day--selected {
                    background-color: #134e3d;
                    color: #ffffff;
                  }

                  &.react-datepicker__day--keyboard-selected {
                    background-color: #134e3d;
                    color: #ffffff;
                  }

                  &:hover {
                    background-color: #134e3d;
                    color: #ffffff;
                  }
                }
              }
            }
          }

          .react-datepicker__today-button {
            font-size: 15px;
            line-height: 21px;
            color: #1c5141;
            font-weight: 600;
            font-family: "Nunito Sans", sans-serif;
            display: block;
            border-top: none;
          }
        }
      }
    }
  }

  .fl-profile-filed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #f9f9f9;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    padding: 10px 15px;
    position: absolute;
    top: 135px;
    left: 0px;
    right: 0px;

    .profile-field {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        object-fit: cover;
        object-position: center;
      }

      h2 {
        font-size: 17px;
        line-height: 26px;
        color: #13513e;
        font-weight: 600;
        font-family: 'Nunito', sans-serif;
        margin-bottom: 0px;
        margin-left: 10px;
      }
    }

    .fl-sels-field {
      display: flex;
      align-items: center;

      select {
        border-radius: 5px;
        background-color: #13513e;
        background-image: url('../img/down-arrow.png');
        background-size: 16px 16px;

        font-size: 14px;
        line-height: 26px;
        color: #ffffff;
        font-weight: 400;
        font-family: 'Nunito', sans-serif;
        margin-left: 20px;
        border: none;
        opacity: 1;
        width: auto;
      }
    }
  }

  .fcl-btn {
    position: absolute;
    right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 74px;

    .fl-btn {
      position: relative;

      .btn {
        border: none;
        box-shadow: none;
        background-color: transparent;

        img {
          width: 22px;
          height: 22px;
          border-radius: 50px;
        }

        svg {
          font-size: 22px;
          width: 22px;
          color: #ffffff;
        }

        &:focus {
          background-color: transparent;
          box-shadow: none;
          border: none;
        }
      }


    }
  }
}

.color-setting-list {
  width: 238px;
  // filter: drop-shadow(0 0 9px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  position: absolute;
  right: 0px;
  z-index: 9;
  padding-top: 15px;
  padding-bottom: 0px;

  h1 {
    font-size: 14px;
    line-height: 26px;
    color: #000000;
    font-weight: 600;
    font-family: "Nunito Sans", sans-serif;
    border-bottom: 1px solid #dddddd;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0px;
    padding-bottom: 7px;
  }

  .csl-list {
    padding: 14px 14px 0px 14px;

    ul {
      padding: 0px;
      margin: 0px;

      li {
        display: flex;
        align-items: center;
        margin: 15px 0px;
        cursor: pointer;
        &:first-child{
          margin-top: 10px;
        }
        .styled-checkbox {
          position: absolute; // take it out of document flow
          opacity: 0; // hide it
        
          & + label {
            position: relative;
            cursor: pointer;
            padding: 0;
            display: flex;
            align-items: center;
          }
        
          // Box.
          & + label:before {
            content: '';
            margin-right: 10px;
            display: inline-block;
            vertical-align: text-top;
            width: 20px;
            height: 20px;
            background: transparent;
            position: absolute;
          }
        
          
         
          // Box checked
          &:checked + label:before {
            background: transparent;
          }
        
          &:checked + label:after {
            content: '';
            position: absolute;
            left: 7px;
            top: 12px;
            background: white;
            width: 2px;
            height: 2px;
            box-shadow: 
            2px 0 0 white,
            4px 0 0 white,
            
            4px -2px 0 white,
            4px -4px 0 white,
            4px -6px 0 white,
            4px -8px 0 white,
            4px -10px 0 white;
            transform: rotate(45deg);
          }
        }
        h3 {
          font-size: 14px;
          line-height: 26px;
          color: #333333;
          font-weight: 600;
          font-family: "Nunito Sans", sans-serif;
          margin-bottom: 0px;
          margin-left: 10px;
        }

        span {
          width: 25px;
          height: 25px;
          border-radius: 13px;
          text-align: center;
          display: block;
          margin-right: 10px;
          input[type="checkbox"]{
            transform: scale(1.5);
            opacity: 0;
            cursor: pointer;
          }
        }

        &.requested {
          margin-top: 0px;

          span {

            background-color: #dbd0eb;

          }
        }

        &.accepted {
          span {
            background-color: #b1cced;
          }
        }

        &.confirmation {
          span {
            background-color: #ffecb3;
          }
        }

        &.confirmed {
          span {
            background-color: #ed8d8b;
          }
        }

        &.show {
          span {
            background-color: #a4e8b9;
          }
        }

        &.Noshow {
          span {
            background-color: #cf0018;
          }
        }

        &.readystart {
          span {
            background-color: #206cc4;
          }
        }

        &.inprogress {
          span {
            background-color: #528d64;
          }
        }

        &.complete {
          span {
            background-color: #c7c7c7;
          }
        }

        &.prtask {
          span {
            background-color: #ebdece;
          }
        }

        &.prtaskblock {
          span {
            background-color: #dbbd99;
          }
        }
      }
    }
  }
}

.event-option-list {
  width: 247px;
  filter: drop-shadow(0 0 9px rgba(0, 0, 0, 0.1));
  background-color: #ffffff;
  padding: 15px 0px;
  position: absolute;
  z-index: 9;

  ul {
    padding: 0px;
    margin: 0px;

    li {
      margin-bottom: 20px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 10px;
      }

      &.event-option-heading {
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 10px;
        margin-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;

        .event-option-field {
          display: flex;
          align-items: flex-start;

          padding-left: 5px;
          padding-right: 5px;

          span {
            margin-right: 10px;

            &.eventname {
              width: 34px;
              height: 34px;
              border-radius: 17px;
              background-color: #13513e;
              font-size: 14px;
              line-height: 26px;
              color: #ffffff;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .event-head {
            text-align: left;
           
            h3 {
              font-size: 15px;
              line-height: 20px;
              color: #000000;
              font-weight: 700;
              font-family: "Nunito Sans", sans-serif;
              margin-bottom: 0px;
            }

            p {
              font-size: 13px;
              line-height: 20px;
              color: #777777;
              font-weight: 400;
              font-family: "Nunito Sans", sans-serif;
              margin-bottom: 0px;
            }

          }
        }
      }

      &.change-status {
        padding-left: 10px;
        padding-right: 10px;
        position: relative;

        .event-option-field {
          display: flex;
          align-items: center;
          background: #f5f5f5;
          padding: 5px 5px 10px 10px;
          cursor: pointer;
          width: 100%;

          span {
            margin-right: 10px;

            &.ch-arrow {
              margin-right: 0px;
              margin-left: 5px;
              position: absolute;
    right: 20px;
            }

            &.statusicon {
              width: 25px;
              height: 25px;
              border-radius: 12px;
              background-color: #dddddd;
              font-size: 14px;
              line-height: 26px;
              color: #ffffff;
              font-weight: 600;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .event-head {
           
            h3 {
              font-size: 14px;
              line-height: 26px;
              color: #333333;
              font-weight: 600;
              font-family: "Nunito Sans", sans-serif;
              margin-bottom: 0px;
            }

            p {
              font-size: 13px;
              line-height: 18px;
              color: #777777;
              font-weight: 400;
              font-family: "Nunito Sans", sans-serif;
              margin-bottom: 0px;
            }

          }
        }

        .color-setting-list {
          position: absolute;
          top: 38px;
          left: 105%;
          padding-top: 0px;

          .csl-list {
            padding-top: 8px;
            padding-bottom: 8px;

            ul {
              li {
                cursor: pointer;
                border-radius: 3px;
                background-color: #f8faf9;
                border: 1px solid #eeeeee;
                padding: 6px 10px;
                margin-bottom: 8px;
                margin-top: 8px;

                &.deny {
                  span {
                    background-color: #7eb0eb;
                  }
                }

                &.Cancle {
                  span {
                    background-color: #b6b6b6;
                  }
                }
              }
            }
          }
        }
      }

      &.event-spt {
        padding-left: 10px;
        padding-right: 10px;

        .event-option-field {
          display: flex;
          align-items: flex-start;

          padding-left: 5px;
          padding-right: 5px;

          span {
            margin-right: 10px;

            &.event-icon {
              width: 27px;
              height: 27px;
              border-radius: 3px;
              background-image: linear-gradient(133deg, #0fb3a7 0%, #40b693 99%, #40b693 100%);
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 7px;

              svg {
                color: #ffffff;
                font-size: 17px;
              }
            }
          }

          .event-head {
            button{
              border: none;
              background: transparent;
              text-align: left;
            }
            h3 {
              font-size: 14px;
              line-height: 18px;
              color: #333333;
              font-weight: 600;
              font-family: "Nunito Sans", sans-serif;
              margin-bottom: 0px;
            }

            p {
              font-size: 13px;
              line-height: 18px;
              color: #777777;
              font-weight: 400;
              font-family: "Nunito Sans", sans-serif;
              margin-bottom: 0px;
            }

          }
        }

      }
    }
  }
}