/* .loader-main-hp {
  margin: 0px;
  text-align: center;
  width: 100%;
  height: 100%;
}
.loader-hp {
  margin: 15px auto;
  border: 7px solid rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  border-top: 7px solid #ffffff;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.small {
  margin: 0 auto;
  border: 4px solid rgba(255, 255, 255, 1);
  border-top: 4px solid #ffffff;
  width: 25px;
  height: 25px;
}

.inverted {
  border: 5px solid #fff;
  border-top: 5px solid #f2f2f2;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} */
.loading {
  position: absolute;
  width: 64px;
  height: 64px;
  margin: auto;
  top: 0px;
  left: 18%;
  right: 0px;
  bottom: 0px;
}
.loader {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.05);
  z-index: 999;
}
.loading div {
  position: absolute;
  background: #145340;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.7s;
}
@keyframes loading {
  0% {
    top: 28px;
    left: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: -1px;
    left: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}
.loader-1{
    background: rgba(0,0,0,.05);
    left: -20%;
    position: fixed;
    z-index: 999;
}

