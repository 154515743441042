@import '../../assets/scss/Responsive.scss';
.booking-layout {
  .main-heading {
    border: none !important;
  }
}
.react-datepicker-wrapper{
  width: 100%;
}
.bookingform-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
&.addbooking-fwr{
  .addbooking-form {
    padding: 20px 40px;
    @include Phone{
      padding: 10px 10px;
    }
    .bookingtype-searchbar{
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
    padding: 15px 30px;
    border-radius: 4px;

    }
    .addbooking{
      input,.searchWrapper{
        background-color: #f9f9f9;
      }
    }
    .bktype-form {
      .basic-multi-select{
        .select__control {
          border: 1px solid #ced4da;
        }
      }
    }
      .basic-multi-select{
        .select__menu{
          z-index: 9999;
        }
        .select__control {
          min-height: auto;
          border-radius: 6px;
    background-color: #f9f9f9;
    border: 1px solid #ced4da;
    font-size: 16px;
    line-height: 26px;
    color: #999999;
    font-weight: 600;
    font-family: 'Nunito', sans-serif;
          .select__input-container{
            .select__input{
              height: 34px;
            }
          }
        }
      }
    
  }
}
  .addbooking-form {
    background-color: #f6f6f6;
    padding: 20px;
    h1{
      position: relative;
      font-size: 23px;
      color: #404040;
      font-weight: 500;
      padding-left:0px;
      font-family: "Rubik", sans-serif;
      margin-bottom:0px;
    }
    .bktype-form{
      // max-width:300px;
      margin-bottom:0px;
      width: 100%;
      select {
        height: 48px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 15px;
        line-height: 26px;
        color: #4d4d4d;
        width: 100%;
        font-weight: 400;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        padding: 0.375rem 0.75rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-family: "Nunito Sans", sans-serif;
      }
      .basic-multi-select {
        .select__control {
            border-radius: 6px;
            background-color: #f9f9f9;
            border: 1px solid #e7e7e7;
            font-size: 16px;
            line-height: 26px;
            color: #999999;
            font-weight: 600;
            font-family: 'Nunito', sans-serif;
        }

        .select__menu {
            div {
                font-size: 16px;
                line-height: 26px;
                font-weight: 600;
                font-family: 'Nunito', sans-serif;
            }
        }
    }
    }
    .form-wrapper {
      &.addbooking{
        margin-top: 30px;
        background-color: #ffffff;
        padding: 20px 30px;
        width: 100%;
        border-radius: 4px;
       h2{
        text-align: center;
        position: relative;
        font-size: 28px;
        color: #404040;
        font-weight: 500;
        padding-left: 0px;
        font-family: "Rubik", sans-serif;
        margin-bottom: 28px;
       }
       .privacypolicy{
        display: flex;
        justify-content: flex-start;
        label{
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          padding-left: 26px;
          left: -21px;
        }
          input{
            padding: 0;
            height: initial;
            width: initial;
            margin-bottom: 0;
            display: none;
            cursor: pointer;
          }
          &::before{
            content:'';
            -webkit-appearance: none;
            background-color: #f9f9f9;
            border:1px solid #ced4da;
            border-radius: 4px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 5px;
            left: 2px;
          }
          input:checked + label:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 5px;
            width: 6px;
            height: 14px;
            border: solid #0c4331;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
       
      }
      .inventory-pdimg {
        display: flex;
        align-items: center;
        input[type="file"] {
          display: none;
        }

        ::-webkit-file-upload-button {
          display: none;
        }

        ::file-selector-button {
          display: none;
        }

        .btn {
          background: #87dc98;
          color: #005740;
          border: none;
          white-space: nowrap;
          margin-left: 10px;
          border-radius: 6px;
          font-size: 16px;
          display: flex;
          align-items: center;
          min-width: 150px;
          text-align: center;
          height: 48px;
          justify-content: center;

          svg {
            fill: #005740;
            width: 20px;
            height: 20px;
          }
        }
      }

      &.addbooking {
        .submitbtn {
          margin-top: 20px;
        }
      }

      .row {
        align-items: baseline;
      }

      label {
        font-size: 16px;
        line-height: 21px;
        color: #343434;
        font-weight: 600;
        font-family: "Nunito Sans", sans-serif;
        display: block;
      }

      .selecttime-field {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        border: none;
        background-color: transparent;
        border-radius: 6px;
        
          &>div{
            &:first-child{
            width: 100%;

          }
          &>div{
            &:first-child{
              box-shadow: none;
              border: 1px solid #ced4da;
            }
          }
        }
        .rsm-date-title{
          font-size: 16px;
        }
        .rsm-start-time-item{
          .rsm-confirm-button{
            padding: 10px;
          }
        }
        .react-calendar{
          .react-calendar__viewContainer{
            .react-calendar {
              &__month-view{
              &__weekdays{
                &__weekday{

                }
              }
              }
              &__month-view__days{
                &__day{

                }
              }
              &__tile{
                height: 45px;
                border-radius: 10px;
                border: none;
                box-shadow: none;
                &--now{

                }
                &--active{
                  border: none;
                  &::after{
                    background: transparent;
                    border: none;
                    position: relative;
                    left: 0px;
                    right: 0px;
                    top: 0px;
                    bottom: 0px;
                  }
                }
                &--range{
                  background: #0c4331;
                  color: #ffffff;
                 
                }
              }
            }
          }
        }
        input {
          border: none;
          border-radius: 6px;
        }

        .react-datepicker-wrapper {
          // &:first-child {
          //   width: 218px;
          //   input {
          //     width: 218px;
          //   }
          // }
        }
      }

      .searchWrapper {
        height: auto;
        border-radius: 6px;
        background-color: white;
        padding-top: 4px;
        padding-bottom: 4px;
        border: 1px solid #ced4da;

        input {
          width: 100px;
          border: none;
          background-color: transparent;
          border-radius: 0px;
          height: auto;
          margin-top: 0px;
        }
      }

      .optionListContainer {
        .optionContainer {
          .notFound {
            font-family: "Nunito Sans", sans-serif;
            font-size: 15px;
            line-height: 26px;
            font-weight: 400;
          }

          .highlightOption {
            background-color: #2b6151;
          }

          .option {
            font-family: "Nunito Sans", sans-serif;
            font-size: 15px;
            line-height: 26px;
            font-weight: 400;

            &:hover {
              background-color: #2b6151;
            }
          }
        }
      }

      input,
      select {
        height: 48px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 1);
        font-size: 15px;
        line-height: 26px;
        color: #4d4d4d;
        width: 100%;
        font-weight: 400;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        appearance: none;
        padding: 0.375rem 0.75rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        font-family: "Nunito Sans", sans-serif;
      }
      input[disabled]{
        color: #999999;
        font-weight: 600;
        background-color: #f9f9f9;
      
      }
      .submitbtn {
        margin-top: 25px;
        margin-bottom: 30px;
        .loading-text{
          display: block;
          text-align: center;
          font-size: 14px;
        }
        .btn {
          background-color: #14513e;
          border: 1px solid #14513e;
          width: 100%;
          max-width: 135px;
          font-size: 16px;
          display: flex;
          align-items: center;
          padding: 8px 0px 8px;
          line-height: 20px;
          svg {
            margin-left: 4px;
            position: relative;
            top:-1px;
          }
        }
      }
    }
  }
}

input::placeholder {
  color: #343434;
  opacity: 1;
}

input:-ms-input-placeholder {
  color: #343434;
}

input::-ms-input-placeholder {
  color: #343434;
}

.selected-field {
  position: relative;
  .error+.downarrow{
    bottom: 38px;
  }
  .downarrow {
    position: absolute;
    bottom: 14px;
    right: 16px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
}

.submit-loader {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -4px;
  svg{
    margin-left: 0px!important;
  }
}